<div id="wrapper" class="container lm-4">

    <div id="indicators" *ngIf="cardDisplayData && cardDisplayData.length > 1" class="mb-4">
        <button class="round_button" (click)="nextCard(); loop=0">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <br /><br />
        <button class="round_button" (click)="prevCard(); loop=0">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </div>

    <br /><br />

    <div id="cards" class="parent">
        <div class="child child-1">
            <mat-card class="card">
                <p class="card_info">Card {{current+1}} out of {{total}}</p>
                <img class="productImage" src={{cardDisplayData[0].img}}>
                <mat-card-header>
                    <mat-card-title>
                        {{cardDisplayData[0].title}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{cardDisplayData[0].subtitle}}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <span class="score">{{cardDisplayData[0].score}}</span>                    
                    <mat-icon *ngIf="(cardDisplayData[0].status === 'rise')" class="green">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="(cardDisplayData[0].status === 'fall')" class="red">keyboard_arrow_down</mat-icon>
                    <p class="info">{{cardDisplayData[0].info}}</p>
                </mat-card-content>
            </mat-card>
        </div>

        <div *ngIf="cardDisplayData && cardDisplayData.length > 1" class="child child-2">
            <mat-card class="card">
                <p class="card_info">Card {{next+1}} out of {{total}}</p>
                <img class="productImage" src={{cardDisplayData[1].img}}>
                <mat-card-header>
                    <mat-card-title>
                        {{cardDisplayData[1].title}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{cardDisplayData[1].subtitle}}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <span class="score">{{cardDisplayData[1].score}}</span>
                    <mat-icon *ngIf="(cardDisplayData[1].status === 'rise')" class="green">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="(cardDisplayData[1].status === 'fall')" class="red">keyboard_arrow_down</mat-icon>
                    <p class="info">{{cardDisplayData[0].info}}</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>


</div>