<mat-toolbar>
    <img class="logo" src="{{logoUrl}}">
    <a class="no-hlink spacer" routerLink="/home" mat-button>Home</a>
    <a class="no-hlink spacer" routerLink="/aboutus" routerLinkActive="active" mat-button>About Us</a>
    <a class="no-hlink spacer" routerLink="/products" routerLinkActive="active" mat-button>Products</a>
    <a class="no-hlink spacer" routerLink="/services" routerLinkActive="active" mat-button>Services</a>
    <a class="no-hlink spacer" routerLink="/careers" routerLinkActive="active" mat-button>Careers</a>
    <a class="no-hlink spacer" routerLink="/contactus" routerLinkActive="active" mat-button>Contact Us</a>
</mat-toolbar>
<div style="margin-top: 10px;"></div>
<ng-image-slider [autoSlide]=1 [infinite]='true' [images]="imageObject" #nav></ng-image-slider>