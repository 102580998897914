<mat-card class="card">
    <img src="assets/boiler.jpg">
    <mat-card-content>
        <mat-card-title>About Us</mat-card-title>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla viverra neque id massa malesuada, sit amet
            faucibus tellus pulvinar. Cras semper justo et orci vehicula gravida sed nec orci. Aenean hendrerit est
            a elit dictum, sit amet laoreet dui pretium. Curabitur est nisl, lacinia non vestibulum ac, cursus ac
            justo. Donec ac dolor urna. Duis eget est in lectus maximus venenatis. Ut quis molestie elit. Integer
            consequat orci eget augue semper, sit amet sodales risus tincidunt. Proin varius arcu eget tellus
            dapibus, in cursus orci cursus. Praesent in posuere massa. Aliquam tempus diam risus, sed semper mi
            iaculis quis. Praesent ut mi augue. Morbi feugiat rhoncus nisl non aliquam. Praesent eget suscipit
            lorem. Mauris mollis pretium neque, sit amet porta justo. Nullam vel mauris quis arcu fringilla
            feugiat.Nullam eu laoreet leo. Fusce dolor orci, pharetra et ante ut, consectetur vulputate odio. Ut
            diam nulla, dignissim et tortor et, lacinia lobortis ex. Ut eu mi id purus rhoncus fringilla eget tempus
            tellus. Fusce mollis libero ut erat porta fringilla. Sed fringilla odio non libero ullamcorper, at
            pellentesque tellus malesuada. Morbi at dapibus velit, nec sodales nisi. In a volutpat lacus, at gravida
            ipsum. Maecenas ut malesuada lacus. Suspendisse non diam et nisl iaculis semper maximus non ex.
            Suspendisse potenti. Etiam vehicula arcu et congue semper. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Fusce et tellus viverra, mattis lorem eget, eleifend enim.
            Aliquam aliquam sodales ullamcorper. Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos. Nullam vel eros laoreet, faucibus urna ut, ultrices nulla.</p>
    </mat-card-content>
</mat-card>

<div class="parallax">
    <div class="banner">
        <div class="title">We provide services in loren ipsum amit sit dolor</div>
        <div class="subtitle">Integer
            consequat orci eget augue semper, sit amet sodales risus tincidunt. Proin varius arcu eget tellus
            dapibus, in cursus orci cursus</div>
    </div>
</div>
<div class="clients">
    <h2>Our Clients</h2>
    <ng-image-slider [autoSlide]=1 [infinite]='true' [images]="imageObject" #nav></ng-image-slider>
</div>

<app-service-signature></app-service-signature>