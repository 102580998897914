<mat-tab-group *ngIf="tabData.length > 1" class="insight">
    <mat-tab *ngFor="let data of tabData" [label]="data.tabLabel">
      <div>
        <div class="mt-4 mb-4">
          <div class="title">{{ data.productTitle }}</div>
          <div class="subtitle">{{ data.productSubtitle }}</div>
        </div>
        <div>
          <div class="score mt-1 pb-1">
            <span>{{ data.score }}</span>
            <span [ngClass]="data.status === 'fall' ? 'red' : 'green'" class="status ml-2">{{ data.statusScore }}
              <span class="material-icons">keyboard_arrow_down</span>
            </span>
          </div>
          <mat-divider></mat-divider>
          <div class="xinfo mt-2">
            {{ data.extraInfo }}
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  
  <div class="insight">
    <div *ngIf="tabData.length == 1">
      <div class="mt-4 mb-4">
        <div class="title">{{ tabData[0].productTitle }}</div>
        <div class="subtitle">{{ tabData[0].productSubtitle }}</div>
      </div>
      <div>
        <div class="score mt-1 pb-1">
          <span>{{ tabData[0].score }}</span>
          <span [ngClass]="tabData[0].status === 'fall' ? 'red' : 'green'"
            class="status ml-2">{{ tabData[0].statusScore }}
            <span class="material-icons">keyboard_arrow_down</span>
          </span>
        </div>
        <mat-divider style="max-width:55%; margin-left: 18%;"></mat-divider>
        <div class="xinfo mt-2">
          {{ tabData[0].extraInfo }}
        </div>
      </div>
    </div>
  </div>