<mat-divider></mat-divider>
<h3>Board of Directors</h3>
<div class="parallax">
    <div class="cards">
        <mat-card class="card">
            <img class="image" src="assets/bod/gajanan.jpeg">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">Gajanan Paralikar</mat-card-title>
                <mat-card-subtitle class="card-subtitle">Director</mat-card-subtitle>
                <mat-card-content class="card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                    cursus vehicula ligula
                    at
                    lobortis.</mat-card-content>
            </mat-card-header>
        </mat-card>
        <mat-card class="card">
            <img class="image" src="assets/bod/dattatray.jpg">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">Dattatray Wagh</mat-card-title>
                <mat-card-subtitle class="card-subtitle">Director</mat-card-subtitle>
                <mat-card-content class="card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                    cursus vehicula ligula
                    at
                    lobortis.</mat-card-content>
            </mat-card-header>
        </mat-card>
        <mat-card class="card">
            <img class="image" src="assets/bod/vinayak.jpeg">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">Vinayak Bansode</mat-card-title>
                <mat-card-subtitle class="card-subtitle">Director</mat-card-subtitle>
                <mat-card-content class="card-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                    cursus vehicula ligula
                    at
                    lobortis.</mat-card-content>
            </mat-card-header>
        </mat-card>
    </div>
</div>
<br>
<mat-divider></mat-divider>
<h3>Company Profile</h3>
<div class="content">
    {{companyProfile}}
</div>

<app-service-signature></app-service-signature>
<mat-divider></mat-divider>

<h3>Audits and Certifications</h3>
<div class="cards">
    <mat-card class="certi-card">
        <mat-card-title class="title">Certificate One</mat-card-title>
        <img class="certi" src="assets/certi/one.png">
        <mat-card-content style="text-align: center !important;">
            <p>Curabitur tellus dui, placerat quis hendrerit quis, facilisis eu lectus.</p>
        </mat-card-content>
    </mat-card>
    <mat-card class="certi-card">
        <mat-card-title class="title">Certificate Two</mat-card-title>
        <img class="certi" src="assets/certi/two.jpg">
        <mat-card-content style="text-align: center !important;">
            <p>Curabitur tellus dui, placerat quis hendrerit quis, facilisis eu lectus.</p>
        </mat-card-content>
    </mat-card>
    <mat-card class="certi-card">
        <mat-card-title class="title">Certificate Three</mat-card-title>
        <img class="certi" src="assets/certi/three.png">
        <mat-card-content style="text-align: center !important;">
            <p>Curabitur tellus dui, placerat quis hendrerit quis, facilisis eu lectus.</p>
        </mat-card-content>
    </mat-card>
</div>