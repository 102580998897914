import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public logoUrl = 'assets/logo.png';

  public imageObject: Array<object> = [{
    video: 'https://youtu.be/g8gw8B939Eo' // Youtube url
  },
  {
    image: 'assets/aone.jpeg',
    thumbImage: 'assets/aone.jpeg',
  },
  {
    image: 'assets/atwo.jpeg',
    thumbImage: 'assets/atwo.jpeg',
  },
  {
    image: 'assets/athree.jpeg',
    thumbImage: 'assets/athree.jpeg',
  },
  {
    image: 'assets/afour.jpeg',
    thumbImage: 'assets/afour.jpeg',
  },
  {
    image: 'assets/afive.jpeg',
    thumbImage: 'assets/afive.jpeg',
  },
  {
    image: 'assets/asix.jpeg',
    thumbImage: 'assets/asix.jpeg',
  },
  {
    image: 'assets/aseven.jpeg',
    thumbImage: 'assets/aseven.jpeg',
  }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
