import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  @Input() cardData: any;

  public current: any;
  public prev: any;
  public next: any;
  public total: any;
  public loop: any;
  public cardDisplayData = [];

  /*
  public cardData = [
    {
      title: 'Milk-Bone has the highest SKU additions',
      subtitle: 'Category - Dog Food',
      score: '35.71%',
      status: 'rise',
      info: 'of the total new products added across all the retailers',
      img: 'assets/aone.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    },
    {
      title: 'Audi has the lowest SKU additions',
      subtitle: 'Category - Cars',
      score: '33%',
      status: 'fall',
      info: 'of the total new products added across all the retailers',
      img: 'assets/atwo.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    },
    {
      title: 'Audi has the largest product additions',
      subtitle: 'Category - Cars',
      score: '71%',
      status: 'rise',
      info: 'of the total new products added across all the retailers',
      img: 'assets/athree.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    },
    {
      title: 'Audi has the highest feature additions',
      subtitle: 'Category - Cars',
      score: '57%',
      status: 'rise',
      info: 'of the total new features added across all the retailers',
      img: 'assets/afour.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    }
  ]

  public data = 
    {
      title: 'Milk-Bone has the highest SKU additions',
      subtitle: 'Category - Dog Food',
      score: '35.71%',
      status: 'rise',
      info: 'of the total new products added across all the retailers',
      img: 'assets/logo.png'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    }

  */


  constructor() { }

  ngOnInit(): void {

    if (this.cardData) {
      this.total = this.cardData.length;
      this.current = 0;
      this.prev = this.cardData.length - 1;
      this.next = 1;
      if (this.cardData.length > 1) {
        this.cardDisplayData[0] = this.cardData[0];
        this.cardDisplayData[1] = this.cardData[1];
      }
      else {
        this.cardDisplayData[0] = this.cardData[0];
      }
      this.loop = 5000;
      this.loopCards();
    }
  }

  nextCard() {
    this.animateCSS('.child-1', 'fadeOutUp').then((message) => {
      this.prev = this.current;
      this.current = this.next;
      this.next = this.next == this.cardData.length - 1 ? 0 : this.current + 1;

      this.cardDisplayData[0] = this.cardData[this.current];
      this.cardDisplayData[1] = this.cardData[this.next];
    });

  }

  prevCard() {

    this.next = this.current;
    this.current = this.prev;
    this.prev = this.prev == 0 ? this.cardData.length - 1 : this.prev - 1;

    this.cardDisplayData[0] = this.cardData[this.current];
    this.cardDisplayData[1] = this.cardData[this.next];

    this.animateCSS('.child-1', 'fadeInDown');
  }

  loopCards() {
    if(this.loop != 0) {
      this.animateCSS('.child-1', 'fadeOutUp').then((message) => {
        this.prev = this.current;
        this.current = this.next;
        this.next = this.next == this.cardData.length - 1 ? 0 : this.current + 1;

        this.cardDisplayData[0] = this.cardData[this.current];
        this.cardDisplayData[1] = this.cardData[this.next];
      });
      this.loop -=1;
      setTimeout(() => { this.loopCards() }, 1500);
    }

  }


  public animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element);

      node.classList.add(`${prefix}animated`, animationName);

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd() {
        node.classList.remove(`${prefix}animated`, animationName);
        resolve('Animation ended');
      }

      node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });

}
