import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

public companyProfile = 'Mauris tincidunt nibh quam, et facilisis tellus aliquam eu. Donec at hendrerit magna, sed consequat mi. Quisque iaculis, mauris et euismod pulvinar, sem felis interdum sem, sit amet ultricies ante nibh ut ipsum. Proin cursus sagittis dui non feugiat. Pellentesque commodo nibh eu venenatis interdum. Integer id nulla semper, dictum risus ut, facilisis eros. Proin interdum, massa non varius tincidunt, ipsum erat lacinia quam, et condimentum sem magna nec sapien. Mauris eu euismod dui, vitae elementum enim. Curabitur venenatis, mi sit amet fermentum imperdiet, dolor magna mattis nibh, condimentum lobortis magna massa id leo. \nEtiam vitae leo a nulla consequat pellentesque. Aenean erat odio, dictum id porta nec, ultrices quis odio. Etiam mattis ligula a sem condimentum, nec mattis mi congue. Phasellus nec ante ipsum. Suspendisse fermentum sodales purus, sed rhoncus massa scelerisque vel. Quisque eget purus et turpis vehicula maximus. Curabitur mattis mauris in ligula consequat viverra. Sed eu massa libero. Suspendisse ut purus rutrum, condimentum libero nec, fringilla nulla. Phasellus maximus, sapien non ultrices volutpat, felis magna laoreet mi, fringilla gravida tellus mauris vitae elit. \nPraesent venenatis commodo euismod. Sed viverra sapien non orci dignissim mollis. Donec quis purus luctus, consectetur libero at, dictum metus. Donec lacinia arcu suscipit ligula maximus euismod. Vivamus in odio vel ante tempus consectetur eget ac odio. Mauris ut turpis et odio vulputate placerat. Fusce vel vestibulum quam, faucibus vulputate tellus. In hac habitasse platea dictumst. Mauris a nisl elit. Cras placerat ipsum et dui accumsan venenatis. Aenean quis facilisis leo. Vivamus interdum tincidunt blandit. In hac habitasse platea dictumst.'

  constructor() { }

  ngOnInit(): void {
  }

}
