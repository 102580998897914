import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public imageObject: Array<object> = [
  {
    image: 'assets/clients/BMW.svg',
    thumbImage: 'assets/clients/BMW.svg',
  },
  {
    image: 'assets/clients/dodge.jpg',
    thumbImage: 'assets/clients/dodge.jpg',
  },
  {
    image: 'assets/clients/lamborghini.png',
    thumbImage: 'assets/clients/lamborghini.png',
  },
  {
    image: 'assets/clients/porsche.jpeg',
    thumbImage: 'assets/clients/porsche.jpeg',
  },
  {
    image: 'assets/clients/rollsroyce.jpg',
    thumbImage: 'assets/clients/rollsroyce.jpg',
  },
  {
    image: 'assets/clients/tesla.png',
    thumbImage: 'assets/clients/tesla.png',
  }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
