<mat-list>
    <mat-list-item role="listitem">
        <mat-icon>phone_in_talk</mat-icon>
        Office : 02025207285
    </mat-list-item>
    <mat-list-item role="listitem">
        <mat-icon>place</mat-icon>
        221B, Baker Street
    </mat-list-item>
</mat-list>

<mat-divider></mat-divider>

<mat-card>
    <img src="/assets/locations/map.png">
</mat-card>