<div class="service-signature">
    <div class="icon">
        <mat-icon [inline]="true">build</mat-icon>
        <p>Manufacturing</p>
    </div>
    <div class="icon">
        <mat-icon [inline]="true">settings</mat-icon>
        <p>Research & Development</p>
    </div>
    <div class="icon">
        <mat-icon [inline]="true">extension</mat-icon>
        <p>Client Services</p>
    </div>
</div>