import { Component, OnInit } from '@angular/core';
declare var ChessBoard: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public board: any;
  public container: any;
  public items: any;
  public currentSlide: any;
  public tempSlide: any;
  public nextSlide: any;
  public delta: any;
  public angle: any;
  public scaling: any;
  public initHandler: any;
  public moveHandler: any;
  public endHandler: any;
  public initialTouch: any;
  public params: any;


  public cardData = [
    {
      title: 'Milk-Bone has the highest SKU additions',
      subtitle: 'Category - Dog Food',
      score: '35.71%',
      status: 'rise',
      info: 'of the total new products added across all the retailers',
      img: 'assets/aone.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    },
    {
      title: 'Audi has the lowest SKU additions',
      subtitle: 'Category - Cars',
      score: '33%',
      status: 'fall',
      info: 'of the total new products added across all the retailers',
      img: 'assets/atwo.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    },
    {
      title: 'Audi has the largest product additions',
      subtitle: 'Category - Cars',
      score: '71%',
      status: 'rise',
      info: 'of the total new products added across all the retailers',
      img: 'assets/athree.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    },
    {
      title: 'Audi has the highest feature additions',
      subtitle: 'Category - Cars',
      score: '57%',
      status: 'rise',
      info: 'of the total new features added across all the retailers',
      img: 'assets/afour.jpeg'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    }
  ]

  public data = 
    {
      title: 'Milk-Bone has the highest SKU additions',
      subtitle: 'Category - Dog Food',
      score: '35.71%',
      status: 'rise',
      info: 'of the total new products added across all the retailers',
      img: 'assets/logo.png'
      //img: 'https://images-na.ssl-images-amazon.com/images/I/71fL0QQG7sL._AC_SY741_.jpg'
    }
  

  constructor() {}
  
  initScroll(event)
    {
      // set initial values
      this.initialTouch = event.clientX;
      this.container.classList.add('sliding');
      this.container.addEventListener('pointermove', this.moveHandler);
  }
  
  moveScroll(event)
    {
      // transition values
      this.delta = this.initialTouch - event.clientX;
      this.angle = this.delta / 100;
      this.scaling = 1 - Math.abs(this.delta) / 5000;
  
      // limit direction on first and last items
      if(Math.sign(this.delta) > 0 && this.currentSlide === this.items.length - 1) return;
      else if(Math.sign(this.delta) < 0 && this.currentSlide === 0) return;
  
      // set different slide if right or left
      let slide = this.tempSlide;
      this.tempSlide = this.delta < 0 ? Math.max(this.currentSlide - 1, 0) : this.currentSlide;
  
      // prevent spam slide stuck
      if(this.tempSlide !== slide)
      {
        this.items[slide].style.transform = `none`;
      }
  
      // update slide position
      this.items[this.tempSlide].style.transform = `translateX(${-this.delta}px) rotate(${-this.angle}deg) scale(${this.scaling})`;
  }
  
  endScroll(event)
    {	
      // reset values
      this.container.removeEventListener('pointermove', this.moveHandler, false);
      this.container.classList.remove('sliding');
      this.items[this.tempSlide].style.transform = `none`;
  
      // you need to slide up to 50px to go prev/next
      if(Math.abs(this.delta) < 50) return;
  
      // reset items classes
      this.items.forEach(i =>
      {
        i.classList.remove('active');
        i.classList.remove('next');
      });
  
      // set current slide
      this.currentSlide = this.delta > 0 ? Math.min(this.tempSlide + 1, this.items.length - 1) : this.tempSlide;
      this.items[this.currentSlide].classList.add('active');
  
      // set next slide
      this.nextSlide = Math.min(this.currentSlide + 1, this.items.length - 1);
      this.items[this.nextSlide].classList.add('next');
  }

  ngOnInit(): void {
    
      // params
      this.params = this.params || {};
      this.container = document.querySelector(this.params.container || '.container');
      this.items = document.querySelectorAll((this.params.container || '.container') + ' > ' + (this.params.items || '.item'));
  
      // transition variables
      this.currentSlide = 0;
      this.tempSlide = this.currentSlide;
      this.nextSlide = Math.min(this.currentSlide+1, this.items.length-1);
      this.delta = 0;
      this.angle = 0;
  
      // handlers
      this.initHandler = event => this.initScroll(event);
      this.moveHandler = event => this.moveScroll(event);
      this.endHandler = event => this.endScroll(event);
  
      // listeners
      this.container.addEventListener('pointerdown', this.initHandler);
      this.container.addEventListener('pointerup', this.endHandler);
      this.container.addEventListener('pointercancel', this.endHandler);
  
      // set item order
      this.items.forEach((i, index) => i.style.zIndex = -index);
      this.items[this.currentSlide].classList.add('active');
      this.items[this.nextSlide].classList.add('next');
  
  }

}
