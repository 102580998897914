import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-signature',
  templateUrl: './service-signature.component.html',
  styleUrls: ['./service-signature.component.scss']
})
export class ServiceSignatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
